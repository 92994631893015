@import 'src/variables';

.container {
  margin-top: 2rem;
  box-sizing: border-box;
  background: black;
  width: 100%;
  padding: 2rem;
  height: auto;

  @media only screen and (max-width: $screen-xxs) {
    padding: 10px;
  }
}

.filterContainer {
  padding-top: 0.5rem;
  text-align: start;
  @media only screen and (max-width: $screen-xxs) {
    padding: 0.5rem;
  }
}

.filterSubContainer {
  display: flex;
  @media only screen and (max-width: $screen-lg) {
    display: block;
  }
}

.filterHeader {
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
  margin: 15px 0
}

.program {
  display: flex;
  text-align: start;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;

  outline: none;
  transition: .3s;

  @media only screen and (max-width: $screen-xxs) {
    border: 2px solid white;
    padding: 0.5rem;
    display: block;
  }

  &:hover {
    @media only screen and (min-width: $screen-xxs) {
      outline: solid white;
      outline-offset: 0.5rem;
    }
  }
}

.session {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  width: calc(100% - 32px);
  overflow-wrap: break-word;
  @media only screen and (max-width: $screen-xxs) {
    width: 100%;
  }
}

.favButton {
  width: 32px;
  margin: 0 auto;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  @media only screen and (max-width: $screen-xxs) {
    padding-top: 0.5rem;
  }

}

.title {
  font-size: 1.5rem;
}

.speaker {
  color: $color-logo-orange;
  font-size: 1.3rem;
}

.subinfo {
  font-size: 1rem;
}

.dayFilter {
  padding-right: 20px;
}

.time {
  text-align: left;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 1.5rem;
}

.day {
  text-align: left;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 2rem
}

.filterButton{
  margin-top: 2rem;
  padding: 0.5rem 2rem;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 3px;
  border: 2px solid white;
  background: none;
  color: white;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: black;
    background: white;
  }
  @media only screen and (max-width: $screen-xxs) {
    width: 100%;
  }
}