@import '../../variables.scss';

.partnerInfo{
  background: $color-logo-blue;
  padding: 1rem;
}

.partnerHeader {
  text-align: center;
  text-transform: uppercase;
}

.partnersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partnerBlock {
  padding: 5px;
}

.logo {
  width: 10rem;
  height: 4.5em;
  &:hover {
    transform: scale(1.2);
  }
}
